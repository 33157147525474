'use client';
import { Button } from '@/src/features/ui/button/button';

type PageParams = {
  status?: string | '404';
};

export const Error404 = ({ status }: PageParams) => {
  return (
    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center align-center px-5 mx-auto my-8">
        <div className="max-w-md text-center flex flex-col items-center justify-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-60">
            <span className="sr-only">{status}</span>
            <span className="sr-only">Error</span>4<span className="text-purple500">0</span>4
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">Üzgünüz, bu sayfayı bulamadık.</p>
          <p className="mt-4 mb-8 dark:text-gray-400">
            Ancak endişelenmeyin, ana sayfada başka pek çok şey bulabilirsiniz.
          </p>
          <Button
            className="w-[50%] p-[8px] mt-4 md:mt-0 h-12 rounded-lg"
            text={'Anasayfa'}
            onClick={() => (window.location.href = '/')}
          ></Button>
        </div>
      </div>
    </section>
  );
};
